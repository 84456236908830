<template>
  <div
    class="clinician-layout"
    @click="SET_SIDEBAR_ADD_STATUS"
  >
    <ModalWrapper />
    <MyAccountClinician />
    <ClinicianNavbar :user="user" />
    <ClinicianSidebar />
    <div class="analytics-layout">
      <AnalyticsTopBar />
      <div class="tab-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import ClinicianNavbar from '@/components/layout/ClinicianNavbar'
import ClinicianSidebar from '@/components/layout/ClinicianSidebar'
import MyAccountClinician from '@/components/clinician/MyAccountClinician'
import AnalyticsTopBar from '@/components/analytics/layout/AnalyticsTopBar'
import { mapGetters, mapActions } from 'vuex'
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'
import ModalWrapper from '@/components/modal/modalWrapper'

export default {
  name: 'AnalyticsLayout',
  components: {
    ModalWrapper,
    ClinicianNavbar,
    AnalyticsTopBar,
    ClinicianSidebar,
    MyAccountClinician
  },
  mixins: [UserManagementComponentMixin],
  data () {
    return {
      searchQuery: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser'
    })
  },
  beforeCreate () {
    document.body.classList.add('body-analytics-layout')
  },
  destroyed () {
    document.body.classList.remove('body-analytics-layout')
  },
  methods: {
    ...mapActions([
      'SET_SIDEBAR_ADD_STATUS'
    ])
  }
}
</script>
