
export const WindowHelper = {
  created () {
    window.addEventListener('blur', this.onBlur)
    window.addEventListener('focus', this.onFocus)
  },
  data () {
    return {
      bluried: false
    }
  },
  methods: {
    onBlur () {
      this.bluried = true
      if (typeof this.windowBlur === 'function') {
        this.windowBlur()
      }
    },
    onFocus () {
      this.bluried = false
      if (typeof this.windowFocus === 'function') {
        this.windowFocus()
      }
    },
    tabIsHidden () {
      return !!(document.hidden || document.msHidden || document.webkitHidden)
    }
  }
}
