<template>
  <div
    class="dropdown contextual-action"
    :class="{show: opened}"
    @mouseleave="close"
  >
    <i
      class="fal fa-trash-alt"
      @click="preConfirm"
    />
    <div
      ref="contextualDropdown"
      class="dropdown-menu dropdown-menu-right"
      :class="{show: opened}"
    >
      <i class="fas fa-caret-up" />
      <p>{{ text }}</p>
      <div class="buttons">
        <button
          type="button"
          class="btn btn-outline-secondary btn-sm"
          @click="close"
        >
          {{ cancelText }}
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="$emit('confirm')"
        >
          {{ confirmText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'ContextualAction',
  mixins: [Helpers],
  props: {
    noPrompt: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: function () { return this.$t('areYouSure') }
    },
    confirmText: {
      type: String,
      default: function () { return this.$t('confirm') }
    },
    cancelText: {
      type: String,
      default: function () { return this.$t('cancel') }
    }
  },
  data () {
    return {
      opened: false
    }
  },
  methods: {
    close () {
      this.opened = false
      this.$emit('cancel')
      document.body.classList.remove('overlay-opened')
    },
    openDialog (element) {
      const position = this.$getElementPosition(element.target)
      this.$refs.contextualDropdown.style.top = `${position.top + 16}px`
      document.body.classList.add('overlay-opened')
      this.opened = true
    },
    preConfirm (element) {
      if (this.noPrompt) {
        this.$emit('confirm')
      } else {
        this.openDialog(element)
      }
    }
  }
}
</script>
