<template>
  <div
    :ref="refName"
    class="dropdown analytics-export-button"
    :class="{show: open}"
  >
    <button
      class="btn btn-outline-secondary btn-sm"
      type="button"
      @click="open = true"
    >
      <i
        v-tooltip="tooltipData"
        :class="buttonIcon"
      />
      {{ $t('exports') }}
      <i
        class="fas"
        :class="{'fa-caret-up': open, 'fa-caret-down': !open}"
      />
    </button>
    <div
      class="dropdown-menu dropdown-menu-right p-0"
      :class="{show: open}"
    >
      <AnalyticsExportList
        :request-errored-out="requestErroredOut"
        @close="closeFunction"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsExportList from '@/components/analytics/export/AnalyticsExportList.vue'
import { EXPORTS_STATUSES, GET_EXPORTS } from '@/store/modules/exports/constants.js'
import { HandleClickOutside } from '@/mixins/HandleClickOutside'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'
import { WindowHelper } from '@/mixins/WindowHelper'

export default {
  name: 'AnalyticsExportButton',
  components: { AnalyticsExportList },
  mixins: [Helpers, WindowHelper, HandleClickOutside],
  data () {
    return {
      open: false,
      refreshInterval: null,
      wasPending: false,
      requestErroredOut: false,
      requestAttempts: 0
    }
  },

  computed: {
    ...mapGetters({
      createExportLoading: 'getCreateExportLoading',
      showExportTooltip: 'getShowExportTooltip',
      owlExports: 'getOwlExports'
    }),
    tooltipData () {
      return {
        content: this.$t('fileBeingGenerated'),
        classes: ['exports-tooltip'],
        placement: 'bottom',
        delay: {
          show: 1000,
          hide: 5000
        },
        trigger: 'manual',
        show: this.showExportTooltip
      }
    },
    someIsPending () {
      return this.createExportLoading || this.owlExports.some(e => e.status === EXPORTS_STATUSES.pending || e.status === EXPORTS_STATUSES.received)
    },
    refreshIntervalAmount () {
      return this.someIsPending || this.requestErroredOut ? 5000 : 1000 * 60 * 5
    },
    buttonIcon () {
      return this.someIsPending ? 'fal fa-spinner fa-spin' : this.wasPending ? 'fas fa-check-circle sucessful' : 'fal fa-file-spreadsheet'
    }
  },
  watch: {
    owlExports (n, o) {
      const exportsWithErrorsToShow = this.getExportsWithErrorToShow(n, o)

      if (exportsWithErrorsToShow && exportsWithErrorsToShow.length) {
        exportsWithErrorsToShow.forEach(err => {
          this.$handleApiError(err.message)
        })
      }
    },
    refreshIntervalAmount (newValue) {
      this.initInterval()
    },
    someIsPending (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.wasPending = true
      }
    }
  },
  created () {
    this.getExports()
    this.initInterval()
  },
  beforeDestroy () {
    this.clearInterval()
  },
  methods: {
    closeFunction () {
      this.open = false
    },
    clearInterval () {
      window.clearInterval(this.refreshInterval)
    },
    initInterval () {
      if (this.refreshInterval) {
        this.clearInterval()
      }
      this.refreshInterval = setInterval(() => {
      // Only get exports if the current tab is active
        if (!this.tabIsHidden()) {
          this.getExports()
        }
      }, this.refreshIntervalAmount)
    },
    getExports () {
      this.$genericDispatch(GET_EXPORTS, null, true, true).then(() => {
        this.requestErroredOut = false
        this.requestAttempts = 0
      }).catch(() => {
        this.requestErroredOut = true
        this.requestAttempts++
      })
    },
    getExportsWithErrorToShow (n, o) {
      return n.filter(newExp => {
        const isWithError = newExp.status === EXPORTS_STATUSES.failed
        let wasInProcess = false
        if (isWithError) {
          wasInProcess = o.find(oldExp => oldExp.id === newExp.id && (oldExp.status === EXPORTS_STATUSES.pending || oldExp.status === EXPORTS_STATUSES.received))
        }
        return isWithError && wasInProcess
      })
    }
  }
}
</script>
