<template>
  <div class="analytics-export-list-item">
    <div class="export-icon">
      <i :class="iconClass" />
    </div>
    <span
      class="export-name"
      :class="{pending: isPending}"
    >
      <p :class="{failed: failed}">{{ item.filename }}</p>
      <span
        v-if="failed"
        class="export-error"
      >{{ item.reason }}</span>
    </span>
    <div class="delete-icon-container">
      <ContextualAction
        :no-prompt="failed"
        :cancel-text="cancelText"
        :confirm-text="confirmText"
        :text="text"
        @confirm="deleteItem"
      />
    </div>
    <div class="export-actions">
      <p v-if="isPending">
        {{ $t('generating') }}
      </p>
      <p v-else-if="failed">
        {{ $t('failed') }}
      </p>
      <button
        v-else
        ref="downloadButton"
        class="btn btn-outline-secondary btn-sm"
        type="button"
        @click="downloadItem"
      >
        {{ $t('download') }}
      </button>
    </div>
  </div>
</template>

<script>
import { EXPORTS_STATUSES, DELETE_EXPORT, DOWNLOAD_EXPORT } from '@/store/modules/exports/constants.js'
import ContextualAction from '@/components/common/ContextualAction.vue'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'AnalyticsExportListItem',
  components: { ContextualAction },
  mixins: [Helpers],
  props: ['item'],
  computed: {
    cancelText () {
      return this.$t('cancel')
    },
    confirmText () {
      return this.$t('delete')
    },
    text () {
      return this.$t('deleteFile')
    },
    isPending () {
      return this.item.status === EXPORTS_STATUSES.pending || this.item.status === EXPORTS_STATUSES.received
    },
    failed () {
      return this.item.status === EXPORTS_STATUSES.failed
    },
    iconClass () {
      switch (this.item.status) {
        case EXPORTS_STATUSES.pending:
        case EXPORTS_STATUSES.received:
          return 'fal fa-spinner fa-spin'
        case EXPORTS_STATUSES.successful:
          return 'fal fa-check-circle'
        case EXPORTS_STATUSES.failed:
          return 'fal fa-exclamation-triangle'
        default:
          return ''
      }
    }
  },
  methods: {
    deleteItem () {
      this.$genericDispatch(DELETE_EXPORT, this.item.id)
    },
    downloadItem () {
      // set focus state for firefox
      this.$refs.downloadButton.focus()

      this.$genericDispatch(DOWNLOAD_EXPORT, {
        id: this.item.id,
        filename: this.item.filename
      })
    }
  }
}
</script>
