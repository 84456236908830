<template>
  <div class="nav flex-row nav-pills">
    <div class="analytics-title layout-title">
      <h3>{{ $t('owlAnalytics') }}</h3>
    </div>
    <div class="links-container">
      <router-link
        v-for="(item, index) in items"
        :key="index"
        :to="{name: item.link}"
        class="nav-link"
        active-class="active"
      >
        {{ item.title }}
      </router-link>
    </div>
    <div class="right-buttons">
      <AnalyticsExportButton v-if="$route.name === 'AnalyticsOutcomesExplorer'" />
    </div>
  </div>
</template>

<script>
import UserManagementComponentMixin from '@/mixins/UserManagement/UserManagementComponentMixin'

import AnalyticsExportButton from '@/components/analytics/export/AnalyticsExportButton.vue'
export default {
  name: 'AnalyticsTopBar',
  components: {
    AnalyticsExportButton
  },
  mixins: [UserManagementComponentMixin],
  computed: {
    items () {
      const linkArray = []
      if (this.loggedUserIsDirector()) {
        linkArray.push({
          title: this.$t('outcomesExplorer'),
          link: 'AnalyticsOutcomesExplorer'
        })
      }
      if (this.loggedUserIsAdmin() || this.loggedUserIsDirector()) {
        linkArray.push({
          title: this.$t('analyticsUsage.usageTabHeader'),
          link: 'AnalyticsUsage'
        })
      }
      return linkArray
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
