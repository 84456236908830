<template>
  <div class="analytics-export-list">
    <div class="analytics-export-list-header">
      <h4>{{ $t('dataExports') }}</h4>
      <div>
        <span>{{ $t('exportsAreRemoved') }}</span>
        <i
          class="fal fa-times"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="analytics-export-list-body">
      <div
        v-if="requestErroredOut"
        class="alert alert-warning"
      >
        <i class="far fa-exclamation-triangle" />
        <span>{{ $t('analyticsExport.reconnectingMessage') }}</span>
      </div>
      <div
        v-if="(!owlExports || !owlExports.length)"
        class="no-exports"
      >
        <p class="first">
          {{ $t('noExport.first') }}
        </p>
        <p class="second">
          {{ $t('noExport.second') }}
        </p>
      </div>
      <AnalyticsExportListItem
        v-for="owlExport in owlExports"
        v-else
        :key="owlExport.id"
        :item="owlExport"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsExportListItem from '@/components/analytics/export/AnalyticsExportListItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AnalyticsExportList',
  components: { AnalyticsExportListItem },
  props: ['requestErroredOut'], // eslint-disable-line vue/require-prop-types
  computed: {
    ...mapGetters({
      owlExports: 'getOwlExports',
      owlExportsLoading: 'getOwlExportsLoading'
    })
  }
}
</script>

<style scoped="scoped">
.alert-warning {
  color: #2F4868;
  background-color: #FCF6CE;
  border-color: #F0DC50;
  font-size: 14px;
}
</style>
